import React, { useEffect } from "react";
import { graphql, PageProps } from "gatsby";
import { useState } from "react";
import styled from "styled-components";
import queryString from "query-string";

import {
  colors,
  fontSizes,
  fontWeights,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { Container, P } from "@util/standard";
import { Accordion, BlocksContent } from "@global";
import { Maybe, Query } from "@graphql-types";
import Layout from "@shared/layout";
import { BreadcrumbPageContext } from "@util/types";

interface Props extends PageProps {
  data: Query;
  pageContext: BreadcrumbPageContext;
}

const Wrapper = styled(Container)`
  width: 100%;
  background-color: ${colors.white};
  justify-content: center;
  flex-direction: column;
  text-align: left;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding-bottom: 0;
  }
`;

const ContentContainer = styled(Container)`
  width: 75%;
  justify-content: center;
  flex-direction: row;
  text-align: left;
  margin: 220px auto 100px auto;
  line-height: 12px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 90%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const TextContainer = styled(Container)`
  flex-direction: column;
  width: 85%;
  margin-left: 4%;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    margin-left: 5%;
  }
`;

const BLocksContentContainer = styled(Container)`
  line-height: 29px;
`;

const Text = styled(P)<{ isSelected?: boolean }>`
  margin: 0;
  font-size: ${fontSizes.button};
  line-height: 35px;
  opacity: ${props => (props.isSelected ? 1 : 0.5)};
  font-weight: ${props =>
    props.isSelected ? `${fontWeights.bold}` : `${fontWeights.normal}`};
  :hover {
    cursor: pointer;
  }
`;

const Mobile = styled(Container)`
  flex-direction: column;
  margin: 100px 30px;
  @media only screen and (min-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const MobileTitle = styled(P)`
  font-size: 20px;
  margin: 10% 0;
`;

const MobileBLocksContentContainer = styled(Container)`
  margin: -20px 0 0 10px;
  p {
    font-size: 14px;
  }
`;

const TermsConditionsPage = ({ data, location, pageContext }: Props) => {
  const { allSanityTermsAndConditionsPage } = data;
  const currentPage = allSanityTermsAndConditionsPage.nodes[0];

  if (currentPage == null || currentPage.termsContent == null) {
    return null;
  }
  useEffect(() => {
    let parsedSearch = queryString.parse(location.search);
    if (parsedSearch && parsedSearch.anchor) {
      let anchor = parsedSearch.anchor as string;
      // setHasSeachTerm(true);
      handleClick(anchor);
    } else {
      // setHasSeachTerm(false);
      handleClick();
    }
  }, [location]);

  if (allSanityTermsAndConditionsPage == null) {
    return null;
  }

  const sectionTitleKeys = currentPage.termsContent.map(term => {
    if (term == null) {
      return;
    }

    return {
      title: term.title,
      key: term.title?.toLowerCase().replace(/ /g, "-"),
      content: term.sectionContent?._rawBlocks,
    };
  });

  const [dataFilter, setDataFilter] = useState(
    sectionTitleKeys[0]?.key as string
  );

  const handleClick = (key?: Maybe<string> | undefined) => {
    setDataFilter(key ?? sectionTitleKeys[0]?.key ?? "");
  };

  const DeskContainer = () => {
    return (
      <ContentContainer>
        <Container flexDirection="column" width="20%">
          {sectionTitleKeys?.map(item => {
            if (item == null) {
              return null;
            }
            return (
              <Text
                key={item.key}
                isSelected={dataFilter === item.key}
                onClick={() => handleClick(item.key)}
              >
                {item?.title}
              </Text>
            );
          })}
        </Container>
        <TextContainer>
          {sectionTitleKeys?.map(item => {
            if (item == null) {
              return null;
            }
            if (item.key === dataFilter) {
              return (
                <BLocksContentContainer key={item.key}>
                  <BlocksContent blocks={item.content} />
                </BLocksContentContainer>
              );
            }
            return null;
          })}
        </TextContainer>
      </ContentContainer>
    );
  };

  const MobileContainer = () => {
    return (
      <Mobile>
        <MobileTitle bold>{currentPage?.title}</MobileTitle>
        {sectionTitleKeys?.map(item => {
          if (item == null) {
            return null;
          }
          return (
            <Container key={item.key} onClick={() => handleClick(item.key)}>
              <Accordion
                title={item?.title as string}
                padding="10px 0 0 0"
                margin="0 0 10px 0"
              >
                {sectionTitleKeys?.map(item => {
                  if (item == null) {
                    return null;
                  }
                  if (item.key === dataFilter) {
                    return (
                      <MobileBLocksContentContainer key={item.key}>
                        <BlocksContent blocks={item.content} />
                      </MobileBLocksContentContainer>
                    );
                  }
                  return null;
                })}
              </Accordion>
            </Container>
          );
        })}
      </Mobile>
    );
  };

  return (
    <Layout template={pageContext.template}>
      <Wrapper>
        <DeskContainer />
        <MobileContainer />
      </Wrapper>
    </Layout>
  );
};

export default TermsConditionsPage;

export const query = graphql`
  query termsAndConditionsQuery($slug: String, $iban: String) {
    allSanityTermsAndConditionsPage(
      filter: {
        slug: { current: { eq: $slug } }
        region: { iban: { eq: $iban } }
      }
    ) {
      nodes {
        ...sanityTermsAndConditionsPage
      }
    }
  }
`;
